import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Spinner, ContentTop, PrimaryButton, SecondaryButton, Pill, Info, SectionContainer, VisualCard, EditCampaign, EstimatedPricesChange, Notification } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getCampaign, updateCampaign, updateTags, getSection } from './CampaignDetailActions';
import { BackArrow } from '../../../components/BackArrow';
import { renderPrice } from '../../../util/price';
import { Grid, List } from 'react-feather';
import { Section, Visual, PillColorStyle, ViewCampaignStyle, Campaign, EstimatedPriceChange, CampaignStatusPrimark } from '../../../interfaces/interfaces';
import { VisualPreview } from '../../../components/VisualPreview';
import { RouteComponentProps } from 'react-router-dom';
import { getDepartments } from '../../../reducers/datatypes/DatatypesActions';
import { start_end_date } from '../../../util/campaign';
import { DropzoneWrapper } from '../../../components/DropzoneWrapper';
import { get_user_token } from '../../../util/cookies';
import { edit_campaign, delete_section,confirm_priorities, update_status,asign_all, set_campaign_thumbnail } from '../../../api';
import { changeVisualPriority, sortVisualArrayByPriority } from '../../../util/visual';
import { StatusGuard } from '../../../components/StatusGuard';
import { RoleGuard } from '../../../components/RoleGuard';
import { get_role, Role } from '../../../util/jwt';
import { ConfirmationPopUp } from '../../../components/ConfirmationPopUp';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_detail_state: state.campaignDetail,
		datatypes_state: state.datatypes,
	}
}

const connector = connect(mapStateToProps, {
	getCampaign,
	getSection,
	getDepartments,
	updateTags,
	updateCampaign,
});

interface ParamTypes {
	id: string
}

type Props = ConnectedProps<typeof connector> & RouteComponentProps<ParamTypes> & WithTranslation;

interface State {
	viewStyle : ViewCampaignStyle
	previewVisualIndex: number | null
	previewVisuals: Visual[] | [],
	showEstimated: boolean,
	showEdit: boolean,
	uploading: boolean,
	showConfirmationPopUp: boolean,
	loadingFromPop: boolean,
	showAsignAllError: boolean,
}

class Detail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			viewStyle : ViewCampaignStyle.GRID,
			previewVisualIndex: null,
			previewVisuals: [],
			showEstimated: false,
			showEdit: false,
			uploading: false,
			showConfirmationPopUp: false,
			loadingFromPop: false,
			showAsignAllError: false
        };
	}

	componentDidMount() {
		this.updateData()
		this.setState({ viewStyle : localStorage.getItem('viewStyle') as ViewCampaignStyle ?? ViewCampaignStyle.GRID })
	}

	updateData() {
		this.props.getCampaign(this.props.match.params.id);
		this.props.getDepartments();
	}

	renderEstimatedModal() {
		const { showEstimated } = this.state;
		const { t } = this.props;
		const { campaign } = this.props.campaign_detail_state;

		if (showEstimated && campaign) {
			const estimatedPricesChange : EstimatedPriceChange[] = [
				{name: t('Total SQM'), price: Number(campaign.to_sqm)},
				{name: t('Total UNITS'), price: Number(campaign.to_units)},
				{name: t('Total ARTWORK'), price: Number(campaign.to_artwork)},
				{name: t('Total SHIPPING'), price: Number(campaign.to_shipping)},
				{name: t('Total CANVAS'), price: Number(campaign.to_canvas)},
				{name: t('Total CAMPAIGN'), price: Number(campaign.to_campaign)},
			]

			return (
				<EstimatedPricesChange
					onClose={() => this.setState({ showEstimated: false })} 
					estimatedPricesChange={estimatedPricesChange}/>
			);
		}
	}

	renderContentTop() {
		const { t } = this.props;
		const { campaign } = this.props.campaign_detail_state;

		var budget = 0
		if (campaign) budget = campaign?.to_sqm + campaign?.to_artwork + campaign?.to_canvas + campaign?.to_shipping + campaign?.to_units

		var statusString = ""
        if (campaign?.status_primark) {
            statusString= campaign?.status_primark.toLowerCase()
            if (statusString === "kendu_processing") { // KENDU_PROCESSING => RATIO_PROCESSING
                statusString = t("ratio_processing")
            } else {
                statusString = t(statusString)
            }
        }

		return (
			<ContentTop>
				<article className="detail-top">
					<div className="detail-top--title">
						<BackArrow onClick={() => this.props.history.goBack()} />
						<div className="title-detail">
							<h2>{ campaign?.title }<span className="state-info-box store-state">{ statusString }</span></h2>
							<p>{ start_end_date(campaign) }</p>
						</div>
					</div>
					<div className="detail-top--actions">
						<RoleGuard roles={[Role.ADMIN, Role.MARKETING]}>
							<Pill color={PillColorStyle.GREY} onClick={() => this.setState({ showEstimated: true })}>{ t('estimated') } <b>{ renderPrice(Number(budget) || 0, 'eur') }</b></Pill>
						</RoleGuard>
						
						<RoleGuard roles={[Role.ADMIN, Role.MARKETING]}>
							<SecondaryButton onClick={() => this.setState({ showEdit : true})} >{ t('edit') }</SecondaryButton>
						</RoleGuard>
						<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.DRAFT]}>
							<RoleGuard roles={[Role.ADMIN, Role.MARKETING]}>
								{!campaign?.is_gallery && <PrimaryButton onClick={() => this.setState({ showConfirmationPopUp : true})} >{ t('confirm_images') }</PrimaryButton>}
							</RoleGuard>
						</StatusGuard>
						<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.KENDU_PROCESSING]}>
							<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
								<PrimaryButton onClick={() => this.setState({ showConfirmationPopUp : true})}  >{ t('confirm_images_with_POI') }</PrimaryButton>	
							</RoleGuard>
						</StatusGuard>
						<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.RATIO_CROP_CONFIRMED]}>
							<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR,Role.HEAD_VM]}>
								<PrimaryButton onClick={() => this.setState({ showConfirmationPopUp : true})} >{ t('confirm_priority') }</PrimaryButton>	
							</RoleGuard>
						</StatusGuard>
						<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.PRIORITY_CONFIRMED]}>
							<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR]}>
								<PrimaryButton onClick={() => this.setState({ showConfirmationPopUp : true})} >{ t('asign_all') }</PrimaryButton>	
							</RoleGuard>
						</StatusGuard>
					</div>
				</article>
			</ContentTop>
		);
	}

	renderPrimarkStatusText(campaign: Campaign | null){
		const { t } = this.props;
		return(<>
			<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.KENDU_PROCESSING]}>
				<Notification title={t("Kendu is processing images")} />
			</StatusGuard>
			<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.RATIOS_CROPPING]}>
				<Notification title={t("Cropping reference image ratios")} />
			</StatusGuard>
			<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.RATIO_CROP_VALIDATING]}>
				<Notification title={t("Waiting ratio crop validation")} />
			</StatusGuard>
			<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.RATIO_CROP_CONFIRMED]}>
				<RoleGuard roles={[Role.MARKETING, Role.COUNTRY_MANAGER, Role.HEAD_VM, Role.STORE_MANAGER]}>
					<Notification title={t("Waiting priority confirmation")} />
				</RoleGuard>
			</StatusGuard>
		</>);			
	}

	renderStatusChangeConfirmationPop(campaign: Campaign | null){
		const { t } = this.props;
		const { showConfirmationPopUp } = this.state;

		let change_text = t("campaing_passing_to_the_next_state");

		const uncompleteSections = campaign?.sections?.filter(section => {
			return section.max_support_number && section.visuals.length < section.max_support_number
		}) 

		if(campaign){
			const next_status_index = (Object.values(CampaignStatusPrimark)).indexOf(campaign.status_primark) + 1;
			if(next_status_index in Object.values(CampaignStatusPrimark))
			{
				change_text = t("Campain passing from state {{statusFrom}} to state {{statusTo}}", { statusFrom: t((campaign.status_primark as string).toLowerCase()), statusTo: t( (Object.values(CampaignStatusPrimark))[next_status_index].toLowerCase() ) } );
			}

			if(campaign.status_primark === CampaignStatusPrimark.RATIO_CROP_CONFIRMED){
				change_text += " "+t("action_takes_time");
			}

			if (campaign.status_primark === CampaignStatusPrimark.DRAFT && uncompleteSections !== undefined && uncompleteSections?.length > 0) {
				change_text += " "+t("didnt_reach_section_recommended") + " " + uncompleteSections.map(section => section.description).join(", ")
			}
		}

		if(showConfirmationPopUp){
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showConfirmationPopUp: false })}
					onSubmit={() => {
						this.setState({ loadingFromPop: true })
						this.changeStatus(campaign)
					}}
					title={t("confirm_campaign_status_change")}
					change_text={change_text}
					warningText= {campaign?.status_primark === CampaignStatusPrimark.PRIORITY_CONFIRMED ? t('other_campaigns_will_end') : undefined}
				/>
			);
		}
	}

	changeStatus(campaign: Campaign | null) {
		if(campaign){
			if(campaign.status_primark === CampaignStatusPrimark.PRIORITY_CONFIRMED){
				asign_all(campaign).then((resp) => {
				
					if (resp === true) {
						this.setState({ showConfirmationPopUp: false });
						this.setState({ loadingFromPop: false });
						this.props.getCampaign(this.props.match.params.id);
					} else {
						this.setState({ showConfirmationPopUp: false });
						this.setState({ showAsignAllError: true });
						this.setState({ loadingFromPop: false });
					}	
					
				}).catch(err => console.error);
			}else if(campaign.status_primark === CampaignStatusPrimark.RATIO_CROP_CONFIRMED){
				confirm_priorities(campaign).then(() => {
					this.setState({ showConfirmationPopUp: false });
					this.setState({ loadingFromPop: false });
					this.props.getCampaign(this.props.match.params.id);
					
				}).catch(err => console.error);
			
			}else{
				update_status(campaign).then(() => {
					this.setState({ showConfirmationPopUp: false });
					this.setState({ loadingFromPop: false });
					this.props.getCampaign(this.props.match.params.id);
				}).catch(err => console.error);
			}
		}
    }

	renderCampaignInfo() {
		const { t } = this.props;
		const { campaign } = this.props.campaign_detail_state;
		const token = get_user_token();
		const role = get_role(token);
		return (
			<Info title={ t('campaign_info') }>
				<div className="title-content-grid">
					<ul className={role==='admin'?"col_r1_d3 mb-16":"col_r1_d2 mb-16"}>
						{role==='admin'&& <li><span>{ t('campaign_code') }</span> { campaign?.id }</li>}
						<li><span>{ t('name') }</span> { campaign?.title }</li>
						<li><span>{ t('date') }</span> { start_end_date(campaign) }</li>
						
					</ul>
					<ul className="col_r2_d4">
						<li><span>{ t('departments') }</span> { campaign?.sections?.map( section => { return section.description }).join(', ') }</li>
					</ul>
				</div>
			</Info>
		);
	}

	renderSubTitle() {
		const { t } = this.props;
		const { viewStyle } = this.state;

		return (
			<section className="inner-title h-opposed">
				<h2 className="v-center">{ t('images_of_departments') }</h2>
				<ul className="type-of-view">
					<li className={ viewStyle === ViewCampaignStyle.GRID ? 'active' : '' }>
						<button onClick={() => {
							this.setState({viewStyle: ViewCampaignStyle.GRID })
							localStorage.setItem('viewStyle', ViewCampaignStyle.GRID)
						}}>
						<Grid />
						</button>
					</li>
					<li className={ viewStyle === ViewCampaignStyle.LIST ? 'active' : '' }>
						<button onClick={() => {
							this.setState({viewStyle: ViewCampaignStyle.LIST})
							localStorage.setItem('viewStyle', ViewCampaignStyle.LIST)
						}}>
						<List />
						</button>
					</li>
				</ul>
			</section>
		);
	}

	renderEdit(campaign : Campaign) {
		const { showEdit } = this.state;

		if (showEdit) {
			return (
				<EditCampaign
					campaign ={ campaign }
					departments={ this.props.datatypes_state.departments }
					onClose={() => this.setState({ showEdit: false })}
					onSubmit={(campaign: Campaign) => {
						edit_campaign(campaign).then(() => {
							this.setState({ showEdit: false })
							this.props.getCampaign(this.props.match.params.id);
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}

	onImagesUploaded() {
		
		setTimeout(() => {
			this.updateData()
		}, 1000);
	}

	changePriority(section_id: string, visual_id:string, new_priority:number){

		const { campaign } = this.props.campaign_detail_state;

		const campaign_section_visuals = campaign!.sections?.find((section: Section) => section.id === section_id)?.visuals || []
		const visuals = changeVisualPriority(campaign_section_visuals, visual_id, new_priority ) 

        const new_campaign_section_visuals = campaign?.sections?.map( section => {
			if(section.id === section_id)
				return {...section, visuals: visuals }
			
			return section
		})
        this.props.updateCampaign({...campaign!, sections: new_campaign_section_visuals})

    }

	renderCampaignSection(section: Section) {
		const { campaign  } = this.props.campaign_detail_state;
		const { t } = this.props

		return (
			<SectionContainer key={ section.description }>
				<section className="work-header">
					<h4>{ section.description }</h4>
					<ul className="supports-asigned">
						<li className="text-info">{ section.visuals.length}/{ section.max_support_number } { t('supports_asigned') }</li>
						{/* <li><a href="#a" className="text-link link-primary link-m">Edit section</a></li> */}
					</ul>
				</section>
				<section className={`section-${this.state.viewStyle}`}>

					{
					sortVisualArrayByPriority( section.visuals )
					.map((visual, index, sectionVisuals) =>
						<VisualCard
							key={ visual.id }
							visual={ visual }
							campaign_status={campaign?.status_primark}
							changePriority = { campaign?.status_primark === CampaignStatusPrimark.RATIO_CROP_CONFIRMED && [Role.ADMIN, Role.BACKDOOR, Role.HEAD_VM].includes(get_role(get_user_token()))  }
							style={ this.state.viewStyle }
							onVisualClick={() => this.setState({ previewVisualIndex: index, previewVisuals: sectionVisuals })}
							priority = { visual.priority}
							max_priority={ section.max_support_number }
							onChangePriorityClick={(new_priority) => this.changePriority(section.id, visual.id, new_priority) }
						/>
					)}
					{ section.visuals.length < section.max_support_number! &&
					<StatusGuard status={campaign?.status_primark} statuses={[CampaignStatusPrimark.DRAFT]}>
						<RoleGuard roles={[Role.ADMIN, Role.BACKDOOR, Role.MARKETING,Role.HEAD_VM]}>
							<DropzoneWrapper
								section={section.description}
								url={`${process.env.REACT_APP_API_URL}/campaigns/upload_images/${campaign?.id}/${section.id}`}
								token={get_user_token()}
								onUploadStarted={() => this.setState({ uploading: true })}
								onUploadCompleted={() => {
									set_campaign_thumbnail(campaign?.id).then(()=>{
										this.onImagesUploaded()
										this.setState({ uploading: false })
									});
									
									
								}}
                                
								max_files={ section.max_support_number! - section.visuals.length }
								
							/>
						</RoleGuard>
					</StatusGuard>
					}
				</section>
			</SectionContainer>
		);
	}

	renderVisualPreview() {
		const { previewVisualIndex, previewVisuals } = this.state;

		if (previewVisualIndex !== null) {
			return (
				<VisualPreview
					previewVisualIndex={previewVisualIndex}
					previewVisuals={previewVisuals}
					onClose={() => this.setState({ previewVisualIndex: null, previewVisuals: [] })}
				/>
			);
		}
	}

	deleteSection(section: Section | null){
		if(section){
			delete_section(section).then(() => {
				this.props.getCampaign(this.props.match.params.id);
			}).catch(err => console.error);
		}
	}

	renderAsignAllErrorPopup() {
		const { t } = this.props
		const { showAsignAllError } = this.state;

		if (showAsignAllError) {
			return(
				<ConfirmationPopUp 
					onClose={() => this.setState({ showAsignAllError: false })}
					onSubmit={() => {
						this.setState({ showAsignAllError: false })
					}}
					title={t("confirm_campaign_status_change")}
					change_text={t("cant_launch_campaign")}
					warningText={""}
				/>
			);
			
		}
	}

	render () {
		const { t } = this.props;
		const { loading, campaign } = this.props.campaign_detail_state;
		const { loadingFromPop } = this.state;

		if (loading || !campaign || loadingFromPop) {
			return (
				<Spinner message={ t('loading') } />
			);
		}
		return (
			<>
				{this.renderEstimatedModal()}
				{this.renderVisualPreview()}
				{this.renderContentTop()}
				{this.renderEdit(campaign)}
				{this.renderPrimarkStatusText(campaign)}
				{this.renderStatusChangeConfirmationPop(campaign)}	
				{this.renderAsignAllErrorPopup()}			
				{this.renderCampaignInfo()}
				{this.renderSubTitle()}
				{campaign.sections?.map((section) => this.renderCampaignSection(section))}
			</>
		);
	}
}

export default withTranslation()(connector(Detail));
